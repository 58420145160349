import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import {
  generateLoginRoute,
  generateResendEmailRoute,
  generateTrialSignupRoute,
  generateSignupStartFreeTrial,
  generateTrialCreateWorkspace,
  generateInvitedSignupRoute,
} from "@router/router-helper";
import { CoreAPITypes } from "@stellar/api-logic";

interface UseAppNavigation {
  /** Navigates to login */
  navigateToLogin(): void;

  /** Navigates to signup trial*/
  navigateToTrialSignup(): void;

  /** Navigates to signup invited */
  navigateToInviteSignup(): void;

  /** Navigates to resend verification email page */
  navigateToResendEmail(): void;

  /** Navigate for the first page free trial */
  navigateToSignupStartFreeTrial(): void;

  /** Navigate for the trial create workspace */
  navigateToTrialCreateWorkspace(provider?: CoreAPITypes.EOAuthProvider): void;
}

/**
 * A hook that gathers all the navigation and routing in the app
 */
export function useAppNavigation(): UseAppNavigation {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  function navigateToLogin(): void {
    navigate({
      pathname: generateLoginRoute(),
      search: searchParams.toString(),
    });
  }

  function navigateToTrialSignup(): void {
    navigate({
      pathname: generateTrialSignupRoute(),
      search: searchParams.toString(),
    });
  }

  function navigateToInviteSignup(): void {
    navigate({
      pathname: generateInvitedSignupRoute(),
      search: searchParams.toString(),
    });
  }

  function navigateToSignupStartFreeTrial(): void {
    navigate({
      pathname: generateSignupStartFreeTrial(),
      search: searchParams.toString(),
    });
  }

  function navigateToTrialCreateWorkspace(
    provider?: CoreAPITypes.EOAuthProvider
  ): void {
    if (provider) {
      searchParams.set("provider", provider);
    }

    navigate({
      pathname: generateTrialCreateWorkspace(),
      search: searchParams.toString(),
    });
  }

  function navigateToResendEmail(): void {
    navigate({
      pathname: generateResendEmailRoute(),
      search: searchParams.toString(),
    });
  }

  return {
    navigateToLogin,
    navigateToTrialSignup,
    navigateToResendEmail,
    navigateToSignupStartFreeTrial,
    navigateToTrialCreateWorkspace,
    navigateToInviteSignup,
  };
}
