import { useMediaQueries } from "@hooks/use-media-queries";
import { Link, Typography } from "@mui/material";

// On localhost, the full path is needed.
const legalPage =
  window.location.hostname === "localhost" ? "/legal/index.html" : "/legal/";

/** Renders terms of use and privacy policy text while sign up */
export function AcknowledgementText(): JSX.Element {
  const { isSmallHeightScreen } = useMediaQueries();

  return (
    <Typography
      variant="caption"
      sx={{
        marginTop: "auto",
        marginBottom: isSmallHeightScreen ? "30px" : "50px",
        textAlign: "center",
      }}
    >
      By proceeding, you agree to the{" "}
      <Link
        target="_blank"
        // e.g. "https://entry.holobuilder.eu/legal/#terms-of-service"
        href={`${legalPage}#terms-of-service`}
        underline="none"
      >
        terms of use
      </Link>{" "}
      and acknowledge you’ve read our{" "}
      <Link
        target="_blank"
        // e.g. "https://entry.holobuilder.eu/legal/#privacy-policy"
        href={`${legalPage}#privacy-policy`}
        underline="none"
      >
        privacy policy
      </Link>
    </Typography>
  );
}
